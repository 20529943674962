.lancrypt-layout {
    c-sidebar-brand {
        background-color: white !important;
    }

    c-sidebar-toggler {
        background-color: $highlight-color;
        color: white;
        border-top: 1px solid white;

        &::before {
            color: white;
        }

        &:hover {
            background-color: $highlight-color;
        }
    }

    .headline-container {
      margin-bottom: 30px;
    }

    .headline-divider {
      margin-bottom: 15px;
    }

    //like create group/asset and their edit views
    .create-edit-container {
        width: 50%;
        min-width: 600px;
        margin: 0 auto;

        mat-form-field {
          width: 100%;
        }
    }

    ng-multiselect-dropdown {
      .multiselect-dropdown .dropdown-btn .selected-item {

        background-color: $primary-color;
        border-color: $primary-color;

        max-width: none;

      }

    }


}
