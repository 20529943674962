.account-layout{
  .body{
    padding-top: 1.5rem;
    mat-card {
      margin: 0 auto;
    }
  }

  .create-edit-container {
    width: 50%;
    min-width: 600px;
    margin: 0 auto;

    mat-form-field {
      width: 100%;
    }
  }

}
