@use '@angular/material' as mat;
/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import styles with default layout.
@import "@coreui/coreui/scss/coreui";

@import "layout";


// If you want to add something do it here
@import "custom";

html, body { height: 100%; }
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: $font-color-primary }

.dense-form-field-2 {
  @include mat.form-field-density(-1);
}
